import {AUTH_LOG_IN, AUTH_LOG_OUT, AUTH_RESET, AUTH_SET} from "../types";

const init = {
    isAuthenticated: false,
    securityQuestions: [],
    forceReEvaluateProgramEvaluation: false,
}

export default function (state=init, action) {
    switch (action.type) {

        case AUTH_LOG_IN:
            return action.payload

        case AUTH_LOG_OUT:
            return {
                ...init,
                isAuthenticated: false
            }

        case AUTH_SET:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }

        case AUTH_RESET:
            return init

        default:
            return state
    }
}