import React, { Fragment, useEffect } from "react";
import ReactHelmet from "../../../components/core/Helmet";
import { useHistory } from "react-router";
import { routes } from "../../../routes";
import Typography from "../../../components/core/typography/Typography";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import Space from "../../../components/core/Space";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import Card from "../../../components/custom/card/Card";
import Button from "../../../components/core/button/Button";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import { useSelector } from "react-redux";
import { getEvaluationStatus } from "../../../redux/actions/universityServicesEvaluation";
import SkeletonLoader from "../../../components/core/loader/SkeletonLoader";
import ErrorLoad from "../../../components/custom/ErrorLoad";
import { CONFIG } from "../../../AppConfig";
import { toggle } from "../../../redux/actions/context";
import NoticeContainer from "../../../components/core/containers/NoticeContainer";

export default function UniversityServicesEvaluation() {
  const history = useHistory();
  const isDownSM = useBreakpointDown("sm");
  const {
    evaluationChecked,
    hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction,
    univServicesEvaluationIsCheckingEvaluationStatus,
    univServicesEvaluationErrorToCheckEvaluationStatus,
    lastRegAYTermForEval,
  } = useSelector((state) => ({
    evaluationChecked: state.CONTEXT.toggles.evaluationChecked,
    hasCompletedFacultyEvaluation:
      state.CONTEXT.toggles.hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction:
      state.CONTEXT.toggles.hasCompletedStudentSatisfaction,
    univServicesEvaluationIsCheckingEvaluationStatus:
      state.CONTEXT.toggles.univServicesEvaluationIsCheckingEvaluationStatus,
    univServicesEvaluationErrorToCheckEvaluationStatus:
      state.CONTEXT.toggles.univServicesEvaluationErrorToCheckEvaluationStatus,
    lastRegAYTermForEval: state.AUTH.lastRegAYTermForEval,
  }));

  useEffect(() => {
    if (!evaluationChecked) getEvaluationStatus();
  }, [
    evaluationChecked,
    hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction,
  ]);

  const evaluationList = [
    {
      title: "Faculty Evaluation",
      description: "Evaluate the performance of each faculty in your class.",
      onClick: () => history.push(routes.universityServicesEvaluationFaculty),
      complete: hasCompletedFacultyEvaluation,
      required: true,
    },
    {
      title: "Student's Satisfaction Survey",
      description:
        "Rate the level of your satisfaction on the identified areas.",
      onClick: () =>
        history.push(routes.universityServicesEvaluationStudentSatisfaction),
      complete: hasCompletedStudentSatisfaction,
      required: true,
    },
    {
      title: "Customer's Feedback",
      description:
        "Evaluate the services rendered by the offices based on the level of your satisfaction.",
      // onClick: () =>
      //   history.push(routes.universityServicesEvaluationClientsFeedback),
      onClick: () => window.open(routes.universityServicesEvaluationClientsFeedbackExternal, '_blank'),
      complete: false,
      required: false,
    },
  ];

  return (
    <Fragment>
      <ReactHelmet title={"University Services Evaluation"} />
      <NoticeContainer
        variant={"warning"}
        text={
          <span>
            The data gathered through this platform will be handled in
            accordance with
            <strong> RA 10173 </strong> or the
            <strong> Data Privacy Act of 2012</strong>, as well as the
            <strong> Data Privacy Policy of USeP</strong>, and will be utilized
            only for the intended purpose.
          </span>
        }
      />

      <Space vertical space={3} />

      <FlexContainer justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant={"h5"} weight={"semibold"}>
          University Services Evaluations
        </Typography>
      </FlexContainer>

      <Space vertical space={3} />

      {univServicesEvaluationIsCheckingEvaluationStatus ? (
        <EvaluationLoader />
      ) : univServicesEvaluationErrorToCheckEvaluationStatus ? (
        <ErrorLoad reload={getEvaluationStatus} />
      ) : (
        <GridContainer spacing={2}>
          {evaluationList.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Card onClick={!item.complete && item.onClick} height={"100%"}>
                <FlexContainer
                  direction={"column"}
                  justifyContent={"space-between"}
                >
                  <div>
                    <Typography variant={"h6"} weight={"semibold"}>
                      {item.title}
                      {item.required && (
                        <Typography
                          color={"error"}
                          variant={"caption"}
                          style={"italic"}
                          span
                        >
                          &nbsp; REQUIRED
                        </Typography>
                      )}
                    </Typography>
                    <Space vertical space={1} />
                    <Typography weight={"light"}>
                      {item.description || "No Description"}
                    </Typography>
                    {lastRegAYTermForEval ? (
                      <div>
                        <Space vertical space={2} />
                        <Typography>{lastRegAYTermForEval}</Typography>
                      </div>
                    ) : (
                      <Space vertical space={1} />
                    )}
                    <Space vertical space={3} />
                  </div>
                  <div>
                    {item.complete ? (
                      <Button
                        variant={"success"}
                        disableHover
                        fullwidth={isDownSM}
                      >
                        Completed
                      </Button>
                    ) : (
                      <Button variant={"primary"} fullwidth={isDownSM}>
                        Evaluate Now
                      </Button>
                    )}
                  </div>
                </FlexContainer>
              </Card>
            </Grid>
          ))}
        </GridContainer>
      )}

      {(CONFIG.ENV_DEV || CONFIG.ENV_STAGING) && (
        <>
          <Space vertical space={3} />
          <Card padding={3}>
            <FlexContainer alignItems={"center"}>
              <Typography>Has completed Faculty Evaluation?</Typography>
              <Space space={3} />
              <Button
                size={"small"}
                variant={hasCompletedFacultyEvaluation && "success"}
                onClick={() => {
                  toggle(
                    "hasCompletedFacultyEvaluation",
                    !hasCompletedFacultyEvaluation
                  );
                }}
              >
                {hasCompletedFacultyEvaluation ? "YES" : "NO"}
              </Button>
            </FlexContainer>

            <Space vertical space={2} />

            <FlexContainer alignItems={"center"}>
              <Typography>
                Has completed Student's Satisfaction Survey?
              </Typography>
              <Space space={3} />
              <Button
                size={"small"}
                variant={hasCompletedStudentSatisfaction && "success"}
                onClick={() => {
                  toggle(
                    "hasCompletedStudentSatisfaction",
                    !hasCompletedStudentSatisfaction
                  );
                }}
              >
                {hasCompletedStudentSatisfaction ? "YES" : "NO"}
              </Button>
            </FlexContainer>
          </Card>
        </>
      )}
    </Fragment>
  );
}

const EvaluationLoader = () => {
  return (
    <div>
      <GridContainer spacing={2}>
        {[1, 2, 3].map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card variant={"light"}>
              <SkeletonLoader width={"75%"} height={36} />
              <Space vertical space={2} />
              <SkeletonLoader height={24} />
              <SkeletonLoader width={"40%"} height={24} />
              <SkeletonLoader width={"60%"} height={24} />
              <Space vertical space={2} />
              <SkeletonLoader width={80} height={40} />
            </Card>
          </Grid>
        ))}
      </GridContainer>
    </div>
  );
};
