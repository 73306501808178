import { CONFIG } from "../AppConfig";
import {
  ACADEMIC_EVALUATION_SET,
  ACCOUNTABILITY_SET,
  AUTH_LOG_IN,
  AUTH_LOG_OUT,
  AUTH_SET,
  DASHBOARD_SET,
  GRADES_SET,
  GRADES_SUMMARY_SET,
  SETTING_TOGGLE_DARK_THEME,
  UNIV_SERVICES_EVALUATION_AY_TERM_SET,
  UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET,
  UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET,
  UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET,
  UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET,
  UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET,
} from "./types";

export const persistWhiteList = ["APP_SETTING", CONFIG.ENV_DEV ? "AUTH" : null];
export const syncWhiteList = [
  AUTH_LOG_IN,
  AUTH_LOG_OUT,
  AUTH_SET,
  SETTING_TOGGLE_DARK_THEME,
  DASHBOARD_SET,
  GRADES_SET,
  GRADES_SUMMARY_SET,
  ACADEMIC_EVALUATION_SET,
  ACCOUNTABILITY_SET,
  UNIV_SERVICES_EVALUATION_AY_TERM_SET,
  UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET,
  UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET,
  UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET,
  UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET,
  UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET,
];
