import React, { Fragment, useRef, useState } from "react";
import ReactHelmet from "../../components/core/Helmet";
import GridContainer from "../../components/core/containers/grid/GridContainer";
import FlexContainer from "../../components/core/containers/flex/FlexContainer";
import Grid from "@material-ui/core/Grid";
import Typography from "../../components/core/typography/Typography";
import Space from "../../components/core/Space";
import Card from "../../components/custom/card/Card";
import CardInfo from "../../components/custom/card/CardInfo";
import ChangePassword from "../../layout/ChangePassword";
import { useSelector } from "react-redux";
import { setForm } from "../../redux/actions/context";
import { useBreakpointDown } from "../../utils/hooks/mediaQuery";
import UpdateProfilePicture from "../../components/Dialogs/UpdateProfilePicture";
import UpdateProfilePictureMobile from "../../components/Dialogs/UpdateProfilePictureMobile";
import { Link, List, ListItem, ListItemText } from "@material-ui/core";

export default function Profile() {
  const isDownSM = useBreakpointDown("sm");
  let {
    studentID,
    name,
    age,
    yearLevel,
    campus,
    college,
    program,
    major,
    curriculum,
    expectedGradYear,
    status,
    ms365Username,
    ms365Password

  } = useSelector((state) => ({
    studentID: state.AUTH.studentID,
    name: state.AUTH.name,
    age: state.AUTH.age,
    yearLevel: state.AUTH.yearLevel,
    campus: state.AUTH.campus,
    college: state.AUTH.college,
    program: state.AUTH.program,
    major: state.AUTH.major,
    curriculum: state.AUTH.curriculum,
    expectedGradYear: state.AUTH.text,
    status: state.AUTH.status,
    ms365Username: state.AUTH.ms365Username,
    ms365Password: state.AUTH.ms365Password
  }));
  age = `${age} years old`;

  return (
    <Fragment>
      <ReactHelmet title={"Profile"} />

      <GridContainer spacing={isDownSM ? 2 : 4}>
        <Grid item xs={12} md={4} lg={4}>
          <Card padding={3}>
            <Typography variant={"h5"} weight={"semibold"}>
              Personal Information
            </Typography>
            <Space vertical space={2} />
            <Typography variant={"caption"}>Name</Typography>
            <Typography weight={"semibold"}>{name}</Typography>
            {/*<Space vertical space={1}/>*/}
            {/*<Typography variant={'caption'}>!! Gender</Typography>*/}
            {/*<Typography weight={'semibold'}>{gender}</Typography>*/}
            <Space vertical space={1} />
            <Typography variant={"caption"}>Age</Typography>
            <Typography weight={"semibold"}>{age}</Typography>
            {/*<Space vertical space={1}/>*/}
            {/*<Typography variant={'caption'}>!! Birth Date</Typography>*/}
            {/*<Typography weight={'semibold'}>{birthdate}</Typography>*/}
            {/*<Space vertical space={1}/>*/}
            {/*<Typography variant={'caption'}>!! Address</Typography>*/}
            {/*{editProfile ?*/}
            {/*    <div>*/}
            {/*        <Space vertical space={1}/>*/}
            {/*        <Input type={'textarea'} value={profileAddress} name={'profileAddress'}*/}
            {/*               onChange={formOnChange}*/}
            {/*        />*/}
            {/*    </div> :*/}
            {/*    <Typography weight={'semibold'}>{address}</Typography>*/}
            {/*}*/}
            {/*<Space vertical space={1}/>*/}
            {/*<Typography variant={'caption'}>!! Mobile Number</Typography>*/}
            {/*{editProfile ?*/}
            {/*    <div>*/}
            {/*        <Space vertical space={1}/>*/}
            {/*        <Input value={profileMobile} name={'profileMobile'}*/}
            {/*               onChange={formOnChange}/>*/}
            {/*    </div> :*/}
            {/*    <Typography weight={'semibold'}>{mobile}</Typography>*/}
            {/*}*/}
            {/*<Space vertical space={1}/>*/}
            {/*<Typography variant={'caption'}>!! Email Address</Typography>*/}
            {/*{editProfile ?*/}
            {/*    <div>*/}
            {/*        <Space vertical space={1}/>*/}
            {/*        <Input value={profileEmail} name={'profileEmail'}*/}
            {/*               onChange={formOnChange}/>*/}
            {/*    </div> :*/}
            {/*    <Typography weight={'semibold'}>{email}</Typography>*/}
            {/*}*/}
            {/*<Space vertical space={3}/>*/}
            {/*{editProfile ?*/}
            {/*    <div>*/}
            {/*        <FlexContainer direction={isDownSM ? 'column' : null} alignItems={'center'}>*/}
            {/*            <Button variant={'success'} onClick={onSaveEditProfile}*/}
            {/*                    fullwidth={isDownSM}>Save</Button>*/}
            {/*            <Space vertical={isDownSM} space={2}/>*/}
            {/*            <Button onClick={onCancelEditProfile}*/}
            {/*                    fullwidth={isDownSM}>Cancel</Button>*/}
            {/*        </FlexContainer>*/}
            {/*    </div> :*/}
            {/*    <Button icon={ballPenLine} variant={'primary'} onClick={toggleEditProfile}*/}
            {/*            fullwidth={isDownSM}>*/}
            {/*        Update Profile</Button>*/}
            {/*}*/}
          </Card>
          <Space vertical space={1} />
          <Card padding={3}>
            <Typography variant={"h5"} weight={"semibold"}>
              Microsoft 365 Account Details
            </Typography>
            <Space vertical space={3} />
            <Typography variant={"caption"}>Username</Typography>
            <Typography weight={"semibold"}>{ms365Username}</Typography>
            <Space vertical space={1} />
            <Typography variant={"caption"}>Default Password</Typography>
            <Typography weight={"semibold"}>{ms365Password}</Typography>
            <Space vertical space={1} />
          </Card>
        </Grid>
        {/* <Grid item xs={12} md={4} lg={4}>
          <Card padding={3}>
            <Typography variant={"h5"} weight={"semibold"}>
              MS365 Account Information
            </Typography>
            <Space vertical space={3} />
            <Typography variant={"caption"}>Username</Typography>
            <Typography weight={"semibold"}>m@mail.com</Typography>
            <Space vertical space={1} />
            <Typography variant={"caption"}>Default Password</Typography>
            <Typography weight={"semibold"}>Hup21184</Typography>
            <Space vertical space={1} />
          </Card>
        </Grid> */}
        <Grid item xs={12} md={4} lg={4}>
          <Card padding={3}>
            <Typography variant={"h5"} weight={"semibold"}>
              Academic Information
            </Typography>
            <Space vertical space={3} />
            <Typography variant={"caption"}>Student ID</Typography>
            <Typography weight={"semibold"}>{studentID}</Typography>
            <Space vertical space={1} />
            <Typography variant={"caption"}>Program</Typography>
            <Typography weight={"semibold"}>{program}</Typography>
            <Space vertical space={1} />
            <Typography variant={"caption"}>Major Study</Typography>
            <Typography weight={"semibold"}>{major || "None"}</Typography>
            <Space vertical space={1} />
            <Typography variant={"caption"}>College</Typography>
            <Typography weight={"semibold"}>{college}</Typography>
            <Space vertical space={1} />
            <Typography variant={"caption"}>Campus</Typography>
            <Typography weight={"semibold"}>{campus}</Typography>
            <Space vertical space={1} />
            <Typography variant={"caption"}>Year Level</Typography>
            <Typography weight={"semibold"}>{yearLevel}</Typography>
            <Space vertical space={1} />
            <Typography variant={"caption"}>Curriculum</Typography>
            <Typography weight={"semibold"}>{curriculum}</Typography>
            <Space vertical space={1} />
            <Typography variant={"caption"}>
              Expected Year to Graduate
            </Typography>
            <Typography weight={"semibold"}>{expectedGradYear}</Typography>
            {/*<Space vertical space={1}/>*/}
            {/*<Typography variant={'caption'}>Status</Typography>*/}
            {/*<Typography weight={'semibold'}>{status}</Typography>*/}
            {/*<Space vertical space={1}/>*/}
            {/*<Typography variant={'caption'}>!! Scholarship</Typography>*/}
            {/*<Typography weight={'semibold'}>None</Typography>*/}
          </Card>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Card padding={3}>
            <ChangePassword />
          </Card>
        </Grid>
      </GridContainer>
      {isDownSM ? <UpdateProfilePictureMobile /> : <UpdateProfilePicture />}
    </Fragment>
  );
}
