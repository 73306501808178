import React, { Fragment, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "./index";
import LoadingComponents from "../components/core/loader/LoadingComponents";
import EnrollmentSurvey from "../pages/menu/enrollment/EnrollmentSurvey";
import Registration from "../pages/menu/enrollment/Registration";
import Register from "../pages/menu/enrollment/Register";
import Schedule from "../pages/menu/enrollment/Schedule";
import FacultyEvaluation from "../pages/menu/servicesEvaluation/FacultyEvaluation";
import StudentSatisfaction from "../pages/menu/servicesEvaluation/StudentSatisfaction";
import CustomersFeedback from "../pages/menu/servicesEvaluation/CustomersFeedback";

export default function HomeSubRoutes() {
  return (
    <Fragment>
      <Suspense fallback={<LoadingComponents mini />}>
        <Switch>
          <Route
            exact
            path={routes.enrollmentSurvey}
            component={EnrollmentSurvey}
          />
          <Route
            exact
            path={routes.enrollmentRegistration}
            component={Registration}
          />
          <Route
            exact
            path={routes.enrollmentRegistrationRegister}
            component={Register}
          />
          <Route
            exact
            path={routes.enrollmentRegistrationRegisterSchedule}
            component={Schedule}
          />
          <Route
            exact
            path={routes.universityServicesEvaluationFaculty}
            component={FacultyEvaluation}
          />
          <Route
            exact
            path={routes.universityServicesEvaluationStudentSatisfaction}
            component={StudentSatisfaction}
          />
          <Route
            exact
            path={routes.universityServicesEvaluationClientsFeedback}
            component={CustomersFeedback}
          />
        </Switch>
      </Suspense>
    </Fragment>
  );
}
