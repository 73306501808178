import React, { useEffect, useState } from "react";
import ReactHelmet from "../../components/core/Helmet";
import Typography from "../../components/core/typography/Typography";
import FlexItem from "../../components/core/containers/flex/FlexItem";
import FlexContainer from "../../components/core/containers/flex/FlexContainer";
import Space from "../../components/core/Space";
import Select from "../../components/core/input/Select";
import Button from "../../components/core/button/Button";
import Grid from "@material-ui/core/Grid";
import GridContainer from "../../components/core/containers/grid/GridContainer";
import refreshLine from "@iconify/icons-ri/refresh-line";
import downloadCloud2Line from "@iconify/icons-ri/download-cloud-2-line";
import Medal from "../../static/media/img/medal.png";
import { getGrades, getGradesSummary } from "../../redux/actions/grades";
import { useSelector } from "react-redux";
import { setContext } from "../../redux/actions/context";
import {
  getGradeColor,
  getRemarks,
  gradeSerializer,
  statSerializer,
} from "../../utils/grades";
import { useBreakpointDown } from "../../utils/hooks/mediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/custom/card/Card";
import Table from "../../components/core/table/Table";
import MessageDialog from "../../components/core/dialogs/MessageDialog";
import { useHistory } from "react-router";
import { routes } from "../../routes";
import ReportOfGrades from "../../generatePDF/reportOfGrades/ReportOfGrades";
import { getEvaluationStatus } from "../../redux/actions/universityServicesEvaluation";
import { GeneratePDF } from "../../generatePDF/pdfGenerator";

export default function Grades() {
  const styles = style();

  const isDownSM = useBreakpointDown("sm");
  const history = useHistory();

  const [generatePDF, setGeneratePDF] = useState(false);
  const [downloadedPDF, setDownloadedPDF] = useState(false);
  const toggleGeneratePDF = () => setGeneratePDF(!generatePDF);

  const {
    campus,
    name,
    studentID,
    program,
    yearLevel,
    profilePicture,
    gradesTermID,
    isGettingGrades,
    isGettingGradesSummary,
    evaluationChecked,
    hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction,
    univServicesEvaluationIsCheckingEvaluationStatus,
    AYTerms,
    summary,
  } = useSelector((state) => ({
    campus: state.AUTH.campus,
    name: state.AUTH.name,
    studentID: state.AUTH.studentID,
    program: state.AUTH.program,
    yearLevel: state.AUTH.yearLevel,
    profilePicture: state.AUTH.profilePicture,
    gradesTermID: state.CONTEXT.gradesTermID,
    isGettingGrades: state.CONTEXT.toggles.isGettingGrades,
    isGettingGradesSummary: state.CONTEXT.toggles.isGettingGradesSummary,
    evaluationChecked: state.CONTEXT.toggles.evaluationChecked,
    hasCompletedFacultyEvaluation:
      state.CONTEXT.toggles.hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction:
      state.CONTEXT.toggles.hasCompletedStudentSatisfaction,
    univServicesEvaluationIsCheckingEvaluationStatus:
      state.CONTEXT.toggles.univServicesEvaluationIsCheckingEvaluationStatus,
    AYTerms: state.GRADES.AYTerms,
    summary: state.GRADES.summary,
  }));
  const termCourses = useSelector((state) => state.GRADES[gradesTermID]);

  let gradesData = [];
  const gradeStatistics = {
    GWA: isGettingGradesSummary ? 0 : summary.GWA,
    units: isGettingGradesSummary ? 0 : summary.units,
    CQPAs: isGettingGradesSummary ? 0 : summary.CQPAs,
    CQPA: isGettingGradesSummary ? 0 : summary.CQPA,
  };

  useEffect(() => {
    if (!evaluationChecked) getEvaluationStatus();
  }, []);

  useEffect(() => {
    if (
      AYTerms.length < 1 &&
      hasCompletedFacultyEvaluation &&
      hasCompletedStudentSatisfaction
    )
      getGrades();
  }, [
    AYTerms.length,
    hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction,
  ]);

  useEffect(() => {
    if (
      gradesTermID &&
      hasCompletedFacultyEvaluation &&
      hasCompletedStudentSatisfaction
    )
      getGradesSummary(gradesTermID);
  }, [
    gradesTermID,
    hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction,
  ]);

  const changeAYTerm = (termID) => {
    setGeneratePDF(false);
    setDownloadedPDF(false);
    setContext("gradesTermID", termID);
  };
  const refresh = () => {
    getGrades();
    getGradesSummary(gradesTermID);
  };
  const goToFacultyEvaluation = () => {
    history.push(routes.universityServicesEvaluationFaculty);
  };
  const goToStudentSatisfaction = () => {
    history.push(routes.universityServicesEvaluationStudentSatisfaction);
  };
  const goBack = () => {
    history.goBack();
  };
  const isDeansList = () => {
    if (isGettingGradesSummary) return false;

    if (gradeStatistics.GWA > 1.75 || gradeStatistics.GWA === 0) return false;

    // check if all subjects are graded and no failed
    for (let subject of gradesData) {
      if (!subject[3]) return false;
      if (subject[4]) return false;
      if (subject[3] > 3) return false;
    }
    return gradesData.length > 0;
  };

  // Table Data
  const gradesColumns = [
    {
      label: "Code",
      align: "left",
      noWrap: true,
      weight: "semibold",
    },
    {
      label: "Title",
      align: "left",
      minWidth: 200,
    },
    {
      label: "Unit",
      align: "center",
    },
    {
      label: "Final",
      align: "center",
      weight: "semibold",
    },
    {
      label: "Re Exam",
      align: "center",
      noWrap: true,
      weight: "semibold",
    },
    {
      label: "Remarks",
      align: "center",
      wrapper: getRemarks,
    },
    {
      label: "Posted On",
      align: "left",
      noWrap: true,
      type: "datetime",
    },
  ];
  if (termCourses && termCourses.length > 0) {
    for (let i of termCourses) {
      gradesData.push([
        i.courseCode,
        i.courseTitle,
        i.creditUnits,
        gradeSerializer(i.final),
        gradeSerializer(i.reExam),
        i.gradeRemarks,
        i.datePosted,
      ]);
    }
  }

  const reportData = {
    profilePicture,
    campus,
    AYSemester: "",
    name,
    studentID,
    program,
    yearLevel,
    gwa: summary.GWA,
    subjects: termCourses,
  };

  if (AYTerms.length > 0) {
    for (let i of AYTerms) {
      if (i.id === gradesTermID) {
        reportData.AYSemester = i.label;
      }
    }
  }

  const canGenerateReport = () => {
    if (isGettingGrades || isGettingGradesSummary) return false;
    if (!reportData.campus) return false;
    if (!reportData.AYSemester) return false;
    if (!reportData.name) return false;
    if (!reportData.studentID) return false;
    if (!reportData.program) return false;
    if (!reportData.yearLevel) return false;
    return reportData.subjects.length !== 0;
  };

  const GradesHeaderDesktop = () => {
    return (
      <>
        <FlexContainer justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"h5"} weight={"semibold"}>
            Report of Grades
          </Typography>
          <FlexItem>
            <FlexContainer alignItems={"center"}>
              <Select
                value={gradesTermID}
                onSelect={changeAYTerm}
                items={AYTerms}
                placeholder={"Academic Year & Term"}
                width={250}
              />
              <Space space={2} />
              <Button
                variant={"success"}
                icon={refreshLine}
                onClick={refresh}
                isLoading={
                  isGettingGrades ||
                  isGettingGradesSummary ||
                  univServicesEvaluationIsCheckingEvaluationStatus
                }
              >
                Refresh
              </Button>
              <Space space={2} />
              {generatePDF ? (
                <GeneratePDF
                  documentComponent={<ReportOfGrades data={reportData} />}
                  filename={`report-of-grades-${reportData.AYSemester}-${reportData.name}`}
                  afterDownloaded={() => {
                    setDownloadedPDF(true);
                    setGeneratePDF(false);
                  }}
                />
              ) : (
                <Button
                  variant={"primary"}
                  icon={!downloadedPDF && downloadCloud2Line}
                  onClick={toggleGeneratePDF}
                  disabled={!canGenerateReport() || downloadedPDF}
                >
                  {downloadedPDF ? "Downloaded" : "Download PDF"}
                </Button>
              )}
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
      </>
    );
  };
  const GradesHeaderMobile = () => {
    return (
      <>
        <Typography variant={"h5"} weight={"semibold"} align={"left"}>
          Report of Grades
        </Typography>
        <Space vertical space={3} />
        <Select
          value={gradesTermID}
          onSelect={changeAYTerm}
          items={AYTerms}
          placeholder={"Academic Year & Term"}
        />
        <Space vertical space={2} />
        <FlexContainer direction={"column"} alignItems={"center"}>
          <FlexItem classNames={[styles.headingItem]}>
            <Button
              variant={"success"}
              icon={refreshLine}
              onClick={refresh}
              fullwidth
              isLoading={
                isGettingGrades ||
                isGettingGradesSummary ||
                univServicesEvaluationIsCheckingEvaluationStatus
              }
            >
              Refresh
            </Button>
          </FlexItem>
          <FlexItem classNames={[styles.headingItem]}>
            <Button
              variant={"primary"}
              icon={downloadedPDF ? null : downloadCloud2Line}
              onClick={toggleGeneratePDF}
              fullwidth
              isLoading={generatePDF}
              disabled={!canGenerateReport() || downloadedPDF}
            >
              <Typography color={"white"} nowrap>
                {downloadedPDF ? "Downloaded" : "Download PDF"}
              </Typography>
            </Button>
          </FlexItem>
        </FlexContainer>
      </>
    );
  };

  return (
    <>
      <ReactHelmet title={"Grades"} />
      {!isDownSM ? <GradesHeaderDesktop /> : <GradesHeaderMobile />}
      {generatePDF && (
        <GeneratePDF
          documentComponent={<ReportOfGrades data={reportData} />}
          loadingComponent={<> </>}
          filename={`report-of-grades-${reportData.AYSemester}-${reportData.name}`}
          afterDownloaded={() => {
            setDownloadedPDF(true);
            setGeneratePDF(false);
          }}
        />
      )}

      <Space vertical space={isDownSM ? 3 : 4} />

      <Table
        columns={gradesColumns}
        data={gradesData}
        initSortIndex={1}
        spacing={!isDownSM && "enlarged"}
      />

      {isDownSM && <Space vertical space={4} />}

      {/* <GridContainer spacing={isDownSM ? 2 : 0} justify={"space-around"}>
        <Grid item xs={12} sm={6} lg={2}>
          {isDownSM ? (
            <Card paddingCustom={"8px 32px 48px 32px"}>
              <FlexContainer
                direction={"column"}
                alignItems={"center"}
                classNames={[styles.statItem]}
              >
                <FlexItem>
                  <FlexContainer alignItems={"center"}>
                    <Typography
                      variant={"h2"}
                      weight={"light"}
                      color={getGradeColor(gradeStatistics.GWA)}
                    >
                      {gradeSerializer(gradeStatistics.GWA)}
                    </Typography>
                    {isDeansList() && (
                      <FlexContainer alignItems={"center"}>
                        <Space space={1.5} />
                        <img src={Medal} alt={"Medal"} height={36} />
                      </FlexContainer>
                    )}
                  </FlexContainer>
                </FlexItem>
                <Space vertical space={1} />
                <Typography align={"center"}>
                  General Weighted Average
                </Typography>
              </FlexContainer>
            </Card>
          ) : (
            <FlexContainer
              direction={"column"}
              alignItems={"center"}
              classNames={[styles.statItem]}
            >
              <FlexItem>
                <FlexContainer alignItems={"center"}>
                  <Typography
                    variant={"h2"}
                    weight={"light"}
                    color={getGradeColor(gradeStatistics.GWA)}
                  >
                    {gradeSerializer(gradeStatistics.GWA)}
                  </Typography>
                  {isDeansList() && (
                    <FlexContainer alignItems={"center"}>
                      <Space space={1.5} />
                      <img src={Medal} alt={"Medal"} height={36} />
                    </FlexContainer>
                  )}
                </FlexContainer>
              </FlexItem>
              <Space vertical space={1} />
              <Typography align={"center"}>General Weighted Average</Typography>
              <Space vertical space={3} />
            </FlexContainer>
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          {isDownSM ? (
            <Card paddingCustom={"8px 32px 48px 32px"}>
              <FlexContainer
                direction={"column"}
                alignItems={"center"}
                classNames={[styles.statItem]}
              >
                <Typography variant={"h2"} weight={"light"}>
                  {statSerializer(gradeStatistics.units)}
                </Typography>
                <Space vertical space={1} />
                <Typography align={"center"}>Units Earned</Typography>
              </FlexContainer>
            </Card>
          ) : (
            <FlexContainer
              direction={"column"}
              alignItems={"center"}
              classNames={[styles.statItem]}
            >
              <Typography variant={"h2"} weight={"light"}>
                {statSerializer(gradeStatistics.units)}
              </Typography>
              <Space vertical space={1} />
              <Typography align={"center"}>Units Earned</Typography>
              <Space vertical space={2} />
            </FlexContainer>
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          {isDownSM ? (
            <Card paddingCustom={"8px 32px 48px 32px"}>
              <FlexContainer
                direction={"column"}
                alignItems={"center"}
                classNames={[styles.statItem]}
              >
                <Typography
                  variant={"h2"}
                  weight={"light"}
                  color={getGradeColor(gradeStatistics.CQPAs)}
                >
                  {gradeSerializer(gradeStatistics.CQPAs)}
                </Typography>
                <Space vertical space={1} />
                <Typography align={"center"}>
                  Cumulative Quality Point Average (including Summer)
                </Typography>
              </FlexContainer>
            </Card>
          ) : (
            <FlexContainer
              direction={"column"}
              alignItems={"center"}
              classNames={[styles.statItem]}
            >
              <Typography
                variant={"h2"}
                weight={"light"}
                color={getGradeColor(gradeStatistics.CQPAs)}
              >
                {gradeSerializer(gradeStatistics.CQPAs)}
              </Typography>
              <Space vertical space={1} />
              <Typography align={"center"}>
                Cumulative Quality Point Average (including Summer)
              </Typography>
              <Space vertical space={2} />
            </FlexContainer>
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          {isDownSM ? (
            <Card paddingCustom={"8px 32px 48px 32px"}>
              <FlexContainer
                direction={"column"}
                alignItems={"center"}
                classNames={[styles.statItem]}
              >
                <Typography
                  variant={"h2"}
                  weight={"light"}
                  color={getGradeColor(gradeStatistics.CQPA)}
                >
                  {gradeSerializer(gradeStatistics.CQPA)}
                </Typography>
                <Space vertical space={1} />
                <Typography align={"center"}>
                  Cumulative Quality Point Average
                </Typography>
              </FlexContainer>
            </Card>
          ) : (
            <FlexContainer
              direction={"column"}
              alignItems={"center"}
              classNames={[styles.statItem]}
            >
              <Typography
                variant={"h2"}
                weight={"light"}
                color={getGradeColor(gradeStatistics.CQPA)}
              >
                {gradeSerializer(gradeStatistics.CQPA)}
              </Typography>
              <Space vertical space={1} />
              <Typography align={"center"}>
                Cumulative Quality Point Average
              </Typography>
              <Space vertical space={2} />
            </FlexContainer>
          )}
        </Grid>
      </GridContainer> */}

      <MessageDialog
        open={
          (!hasCompletedFacultyEvaluation ||
            !hasCompletedStudentSatisfaction) &&
          evaluationChecked
        }
        variant={"primary"}
        title={
          !hasCompletedFacultyEvaluation
            ? "Faculty Evaluation"
            : "Student Satisfaction"
        }
        onCloseLabel={"Back"}
        onClose={goBack}
        description={`
                    Please complete the 
                    ${
                      !hasCompletedFacultyEvaluation
                        ? "Faculty Evaluation"
                        : "Student Satisfaction evaluation"
                    } first to see report of grades.
                   `}
        onConfirmLabel={
          !hasCompletedFacultyEvaluation
            ? "Faculty Evaluation"
            : "Student Satisfaction"
        }
        onConfirm={
          !hasCompletedFacultyEvaluation
            ? goToFacultyEvaluation
            : goToStudentSatisfaction
        }
      />
    </>
  );
}

const style = makeStyles((theme) => ({
  headingItem: {
    marginBottom: theme.spacing(1.5),
    width: "100%",
  },
  statItem: {
    paddingTop: theme.spacing(4),
  },
}));
