import {
    UNIV_SERVICES_EVALUATION_AY_TERM_SET,
    UNIV_SERVICES_EVALUATION_CLEAR,
    UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET,
    UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET,
    UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET,
    UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET,
    UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET
} from "../types";

const init = {
    facultyEvaluationForm: {},
    facultyList: [],
    ayTerm: '',
    studentSatisfactionForm: {},
    customerFeedbackForm: {},
    officesToEvaluate: {
        // frontline: [],
        // nonFrontline: [],
    },
}

export default function (state = init, action) {
    switch (action.type) {

        case UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET:
            return {
                ...state,
                facultyList: action.payload
            }

        case UNIV_SERVICES_EVALUATION_AY_TERM_SET:
            return {
                ...state,
                ayTerm: action.payload
            }

        case UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET:
            return {
                ...state,
                facultyEvaluationForm: action.payload
            }

        case UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET:
            return {
                ...state,
                studentSatisfactionForm: action.payload
            }

        case UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET:
            return {
                ...state,
                customerFeedbackForm: action.payload
            }

        case UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET:
            return {
                ...state,
                officesToEvaluate: action.payload
            }

        case UNIV_SERVICES_EVALUATION_CLEAR:
            return init

        default:
            return state
    }
}